import logo from "../src/assets/images/brands/logoData.png";

export const clientName = "9Cents";
export const developedBy = "9Cents";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "9Cents";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;